import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const DefaultFooter = () => import(/* webpackChunkName: "default-footer" */ '@/layouts/DefaultFooter.vue')
const Default = () => import(/* webpackChunkName: "default" */ '@/layouts/Default.vue')

const routes = [
  {
    path: '',
    component: Default,
    props: true,
    children: [
      {
        path: '/app/alarms',
        name: 'home',
        components: {
          default: () => import(/* webpackChunkName: "alarms-home" */ '../views/HomeView.vue'),
          footer: DefaultFooter
        },
        props: true
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
